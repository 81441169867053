import { User } from "../User";
import { UserToken } from "./UserToken";

export class UserLoginResponse {
    public token!: UserToken;
    public user!: User;
    public constructor(model: Partial<UserLoginResponse>) {
        if (model && model.user) {
            this.user = new User(model.user);
        }
        if(model && model.token) {
            this.token = new UserToken(model.token);
        }
    }
}