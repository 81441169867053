import { Directive } from "@vue/runtime-core";

const removeClasses = (el: HTMLElement) => {
  el.classList.remove("v-l-success");
  el.classList.remove("v-l-error");
  el.classList.remove("v-l-loading");
  el.classList.remove("v-l-disabled");
  el.classList.remove("v-l-none");
};

const defineClass = (value: LoadingStatus) => {
  switch (value) {
    case LoadingStatus.Success:
      return "v-l-success";
    case LoadingStatus.Error:
      return "v-l-error";
    case LoadingStatus.Loading:
      return "v-l-loading";
    case LoadingStatus.Disabled:
      return "v-l-disabled";
    case LoadingStatus.None:
    default:
      return "v-l-none";
  }
};

export enum LoadingStatus {
  None = 0,
  Loading = 1,
  Success = 2,
  Error = 3,
  Disabled = 4,
}

export const LoadingDirective: Directive = {
  mounted(el: HTMLElement, binding) {
    el.classList.add("v-l");
    removeClasses(el);
    el.classList.add(defineClass(binding.value));
    if (el.nodeName === "BUTTON" && (binding.value === LoadingStatus.Loading || binding.value === LoadingStatus.Disabled)) {
      el.setAttribute("disabled", "true");
    } else {
      el.removeAttribute("disabled");
    }
  },
  updated(el: HTMLElement, binding) {
    removeClasses(el);
    el.classList.add(defineClass(binding.value));
    if (el.nodeName === "BUTTON" && (binding.value === LoadingStatus.Loading || binding.value === LoadingStatus.Disabled)) {
      el.setAttribute("disabled", "true");
    } else {
      el.removeAttribute("disabled");
    }
  },
};
