import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26e32406"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
      return (_openBlock(), _createElementBlock("ul", {
        key: toast.id
      }, [
        _createElementVNode("li", {
          class: _normalizeClass(toast.type)
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(toast.title), 1),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(toast.message, (msgLine, index) => {
              return (_openBlock(), _createElementBlock("p", {
                key: index,
                class: "toast-line"
              }, _toDisplayString(msgLine), 1))
            }), 128))
          ])
        ], 2)
      ]))
    }), 128))
  ]))
}