
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "AppContainer",
  computed: {
    ...mapGetters({
      isLeftSidebarPinned: "isLeftSidebarPinned",
    }),
    isLeftSidebarDisabled() {
      return this.$route.meta.disableLeftSidebar;
    },
    isRecentLogsAvailable() {
      const currentRoute = this.$route.name as string;
      return  currentRoute.includes("folders") && this.$store.getters.environmentRecentActionLogs.totalItems > 0;
    },
  },
});
