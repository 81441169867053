export type UserInvitation = {
  id: number;
  invitedBy: string;
  productId: number;
  productName: string;
  state: "NONE" | "PENDING" | "EXPIRED" | "REVOKED" | "ACCEPTED";
};

export type IUser = {
  hid: string;
  email: string;
  isEmailVerified: boolean;
  lastLoginAt?: string;
  name: string;
  username: string;
  userProduct?: any[];
}

export class User implements IUser {
  public hid = "";
  public email = "";
  public name = "";
  public username = "";
  public password = "";
  public passwordConfirmation = "";
  public isEmailVerified = false;
  public invitationCode = "";
  public invitations?: UserInvitation[]; // used for onboarding

  public constructor(model: Partial<User>) {
    Object.assign(this, model);
  }
}
