import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/users/Login.vue";
import { store } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false,
      maintainBreadCrumbsX: false,
    },
  },
  {
    path: "/",
    name: "home",
    redirect: {
      name: "dashboard",
    },
    component: Home,
    meta: {
      requiresAuth: true,
      maintainBreadCrumbsX: false,
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        redirect:{
          name: "orgs-dashboard"
        },
        meta: {
          requiresAuth: true,
          maintainBreadCrumbsX: false,
          disableLeftSidebar: true,
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/dashboard/Dashboard.vue"),
        children: [
          {
            path: "orgs-dashboard",
            name: "orgs-dashboard",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "organizationDashboard" */ "../views/dashboard/Organizations.vue"),
          },
          {
            path: "users-dashboard",
            name: "users-dashboard",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "organizationDashboard" */ "../views/dashboard/Users.vue"),
          },
          {
            path: "blocked-orgs",
            name: "blocked-orgs",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "dashboard" */ "../views/dashboard/BlockedOrganizations.vue"),
          }
        ]
      },
      {
        path: "organization/:orgHid",
        name: "org-detail",
        meta: {
          requiresAuth: true,
          maintainBreadCrumbsX: false,
          disableLeftSidebar: true,
        },
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/organizations/OrganizationDetail.vue"),
        children: [
          {
            path: "users",
            name: "org-users",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "organizationDashboard" */ "../views/organizations/OrganizationUsers.vue"),
          },
          {
            path: "products",
            name: "org-products",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "organizationDashboard" */ "../views/organizations/OrganizationProducts.vue"),
          },
          {
            path: "api-usage",
            name: "org-api-usage",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/OrganizationApiUsage.vue"),
          },
          {
            path: "assets-usage",
            name: "org-assets-usage",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "env" */ "../views/organizations/AssetsUsage.vue"),
          },
          {
            path: "api-keys",
            name: "org-api-keys",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "organizationDashboard" */ "../views/organizations/OrganizationApiKeys.vue"),
          },
          {
            path: "service-product-maps",
            name: "org-svc-prod-maps",
            meta: {
              requiresAuth: true,
              maintainBreadCrumbsX: false,
            },
            component: () => import(/* webpackChunkName: "organizationDashboard" */ "../views/organizations/OrganizationServiceProductMaps.vue"),
          }
        ]
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (store.getters.isAwaitingToken) {
    await store.dispatch("tokenFromStorage");
  }

  if (!to.meta.maintainBreadCrumbsX) {
    store.commit("breadCrumbsXClear"); // if we are not going to one of the views that we should maintain the breadcrumb, then always clear it.
  }

  if (to.meta.requiresAuth) {
    if (!store.getters.isAuthenticated) {
      const currentLocation = encodeURI(window.location.pathname);
      store.commit("setLoading", false);
      next(`/login?continue=${currentLocation}`);
    } else {
      if (to.meta.showLoader && !store.getters.appIsLoaded) {
        store.commit("setLoading", true); // Show the long loader on bigger pages that will take longer to load on slower connections.
      }
      next();
    }
  } else {
    next();
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach(async (to, from) => {
  if (to.meta.showLoader) {
    store.commit("setLoading", false);
  }
});

export default router;
