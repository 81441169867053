
import { defineComponent } from "vue";
import AppHeader from "@/components/layout/Header.vue";
import AppLeftSideBar from "@/components/layout/LeftSideBar.vue";
import AppContainer from "@/components/layout/Container.vue";
import Modal from "@/components/Modal.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleDown,
  faAngleRight,
  faBook,
  faPlus,
  faKey,
  faFolderOpen,
  faFileCode
} from "@fortawesome/free-solid-svg-icons";
import { store } from "@/store";
import { toastAdd } from "@/components/toasts/toastHelper";
import { mapGetters } from "vuex";

library.add(faAngleLeft, faAngleDown, faBook, faPlus, faKey, faAngleRight, faFolderOpen, faFileCode);

export default defineComponent({
  name: "AppHome",
  components: {
    AppHeader,
    AppLeftSideBar,
    AppContainer,
    Modal
  },
  data() {
    return {
      store,
      suppressNoAuthModal: false, // Allow user suppress so this does not keep on popping up.
    };
  },
  computed: {
    ...mapGetters({
      userIsVerified: "userIsVerified",
      user: "user",
    }),
    showNoAuthModal(): boolean {
      // When a 401 is returned on BaseService, the token is cleared. This will trigger.
      return (this.$route.meta.requiresAuth as boolean) && !this.store.getters.isAuthenticated && !this.suppressNoAuthModal;
    },
  },

  methods: {
    logout(): void {
      this.store.dispatch("tokenLogout");
      this.$router.push({
        name: "login",
        query: { continue: this.$route.path },
      });
      toastAdd(
        "You have logged out successfully. See you soon!",
        "Log Out Success"
      );
    },
  },
});
