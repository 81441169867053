import { User } from "@/models/User";
import { UserLoginResponse } from "@/models/users/UserLoginResponse";
import { UserToken } from "@/models/users/UserToken";
import { request, RequestOptions } from "./BaseService";
import { store } from "@/store";
import { DataRes } from "@/models/DataRes";
import { ListPageModel } from "@/models/ListPageModel";
import { UserDashboardDto } from "@/models/users/UserDashboardDto";

const endpoint = "/user";

export const getUser = async (options: RequestOptions = {}): Promise<DataRes<User>> => {
  const user = await request<User>(
    `${endpoint}`,
    {
      method: "GET",
    },
    options
  );

  user.data = new User(user.data);
  return user;
};

export const loginUser = async (email: string, password: string): Promise<DataRes<UserLoginResponse>> => {
  const registered = await request<UserLoginResponse>(`${endpoint}/login`, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
    }),
  });

  registered.data = new UserLoginResponse(registered.data);
  return registered;
};

export const refreshTokenUser = async (refreshToken: string): Promise<DataRes<UserToken>> => {
  const token = await request<UserToken>(`${endpoint}/refreshtoken`, {
    method: "POST",
    body: JSON.stringify({
      refreshToken,
    }),
  });

  token.data = new UserToken(token.data);
  return token;
};

export const attemptTokenFromStorage = async (): Promise<void> => {
  const token = localStorage.getItem("authToken");
  if (token == null) {
    return;
  }
  const tokenParsed: UserToken = JSON.parse(token);
  store.commit("tokenUpdate", tokenParsed);
  store.dispatch("tokenRefresh");
};

export const getUserDashboardPage = async (page: number, pageSize: number): Promise<DataRes<ListPageModel<UserDashboardDto>>> => {
  const list = await request<ListPageModel<UserDashboardDto>>(
    `${endpoint}/dashboard?page=${page}&pageSize=${pageSize}`,
    { method: "GET" },
  );
  list.data.page = list.data.page.map((x: UserDashboardDto) => new UserDashboardDto(x));
  return list;
};

export const toggleEnableUser = async (hid: string) : Promise<DataRes<boolean>> => {
  return await request<boolean>(`${endpoint}/${hid}/toggleEnable`, { method: "POST" },);
}