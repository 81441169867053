import { datadogLogs } from "@datadog/browser-logs";
import { config } from "@/config";

export const initDataDog = () => {
  if (process.env.VUE_APP_DATADOG_ENABLED === "true") {
    const env = config.appEnv;
    datadogLogs.init({
      clientToken: "pubf30eee58025dbc15ab4a911767d4fe2c",
      service: "opsdeck_admin_client",
      version: config.appVersion,
      env,
      beforeSend: (log) => {
        if (log.message == "ResizeObserver loop limit exceeded") {
          return false; // Suppress this. Happens on configversions.vue. Spams the logs.
        }
      },
    });
  }

  return datadogLogs;
};
