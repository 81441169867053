export class UserDashboardDto {
    public hid = "";
    public email = "";
    public lastLoginDate = "";
    public isDisabled = false;
    public role  = UserOrganizationRoles.None;

    public constructor(model: Partial<UserDashboardDto>) {
        Object.assign(this, model);
    }
}

export enum UserOrganizationRoles {
    None = 0,
    Owner = 1,
    Editor = 2,
    Member = 3,
  }
  
  
  export const USER_ORG_ROLES = {
    [UserOrganizationRoles.None]: "",
    [UserOrganizationRoles.Owner]: "Owner",
    [UserOrganizationRoles.Editor]: "Editor",
    [UserOrganizationRoles.Member]: "Member",
  };
  