
import { setup, Vue } from "vue-class-component";
import { loginUser } from "@/services/UserService";
import { useStore, State } from "@/store";
import { Store } from "vuex";
import { DataResStatus } from "@/models/DataRes";
import { toastAdd } from "@/components/toasts/toastHelper";
import { LoadingStatus } from "@/directives/LoadingDirective";
import { LocationQueryValue } from "vue-router";

export default class OnboardingComponent extends Vue {
  email = "";
  password = "";
  store = setup<Store<State>>(() => useStore());
  loadingStatus: LoadingStatus = LoadingStatus.None;

  async login(): Promise<void> {
    this.loadingStatus = LoadingStatus.Loading;
    const loggedIn = await loginUser(this.email, this.password);
    if(loggedIn.status === DataResStatus.Ok) {
      this.store.commit("userUpdate", loggedIn.data.user);
      this.store.commit("tokenUpdate", loggedIn.data.token);
      this.store.dispatch("tokenAutoRefresh");
      this.$router.push(this.$route.query.continue as LocationQueryValue || "/products");
      toastAdd("Welcome to Joystick.", "Hello!");
    }
    this.loadingStatus = LoadingStatus.Success;

  }
}
