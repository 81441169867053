
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
  },
  computed: {
    ...mapGetters({
      isLeftSidebarPinned: "isLeftSidebarPinned",
    }),
    isLeftSidebarDisabled() {
      return this.$route.meta.disableLeftSidebar;
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.commit("toggleLeftSidebar");
    },
  },
});
