
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";

@Options({
  props: {
    date: String,
  },
})
export default class Date extends Vue {
  public date!: string;
  public get displayDate() {
    return dayjs(this.date).format("YYYY-MM-DD HH:mm:ss");
  }
}
