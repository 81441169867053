import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import { Vue } from "vue-class-component";
import { initDataDog } from "./services/DataDogService";

// 3d party.
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "vue-select/dist/vue-select.css";
import "remixicon/fonts/remixicon.css";
import VueApexCharts from "vue3-apexcharts";

import DateComponent from "@/components/Date.vue";
import AuthorComponent from "@/components/Author.vue";

import { LoadingDirective } from "./directives/LoadingDirective";

// initialize datadog logging service
initDataDog();

library.add(faUserSecret);

// Register the router hooks with their names
Vue.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

const app = createApp(App);
app.use(store, key);
app.use(router);
app.directive("loading", LoadingDirective);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("DateDisplay", DateComponent);
app.component("AppAuthor", AuthorComponent);

app.use(VueApexCharts);

// TODO: value:any is not good. Fix it.
// TODO: Fix: if just using a regular string, it will put quotes around it. Make able to handle both regular string and JSON.
app.config.globalProperties.$filters = {
  str_limit(value: any, size: number, processAsString = false): string {
    if (!value) return "";
    value = processAsString ? value : JSON.stringify(value);

    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + "...";
  },
  string_to_color(str: string): string {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  },
};
app.mount("#app");
