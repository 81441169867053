
import { defineComponent } from "vue"
import JoystickTextLogo from "@/components/svgIcons/JoystickTextLogo.vue";
import { toastAdd } from "@/components/toasts/toastHelper";
import { config as clientAppConfig } from "@/config";

export default defineComponent({
  name: "AppHeader",
  components: {
    JoystickTextLogo,
  },
  computed: {
    clientAppVersion(): string {
      return clientAppConfig.appVersion;
    },
  },
  methods: {
    logout(): void {
      this.$store.dispatch("tokenLogout");
      this.$router.push({
        name: "login",
        query: { continue: this.$route.path },
      });
      toastAdd(
        "You have logged out successfully. See you soon!",
        "Log Out Success"
      );
    },
  },
});
